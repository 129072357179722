<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-dialog
                v-model="modalForm"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  Add Site
                </v-btn>
              </template>
              <ModalForm v-bind:form-action="addOrEdit" v-bind:model-id="modalModelId" v-on:closeModal="modalForm = false"></ModalForm>
            </v-dialog>
          </div>
        </v-card-title>
        <v-card-title>
          <strong>
            <v-icon x-large>mdi-map-marker-radius</v-icon>
          </strong> Sites
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="sites"
            item-key="name"
            show-select
            class="elevation-1 table-one"
            multi-sort
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.occupancy="{item}">
            <div class="d-flex">

              <template>
                <v-progress-linear
                    :value="item.occupancy"
                    width="50"
                    height="10"
                    striped
                    color="deep-orange"
                />
              </template>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ModalForm from "./SiteModal"

export default {
  components: {
    ModalForm
  },
  metaInfo: {
    title: 'Site'
  },
  data() {
    return {
      modalForm: false,
      addOrEdit: "Add",
      modalModelId: null,
      search: '',
      selected: [],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          align: 'left',
        },
        {
          text: 'Description',
          sortable: true,
          value: 'description',
          align: 'left',
        },
        {
          text: 'Address 1',
          sortable: true,
          value: 'address1',
          align: 'left',
        },
        {
          text: 'Address 2',
          sortable: true,
          value: 'address2',
          align: 'left',
        },
        {
          text: 'Town',
          sortable: true,
          value: 'town',
          align: 'left',
        },
        {
          text: 'Postcode',
          sortable: true,
          value: 'postcode',
          align: 'left',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('sites', {loading : 'isFindPending'}),
    ...mapGetters('sites', {findSitesInStore : 'find'}),

    sites() {
      return this.findSitesInStore().data || [];
    }
  },
  methods: {
    ...mapActions('sites', { findSites: 'find' }),

    deleteItem(id) {
      this.$store.dispatch('sites/remove', id)
    }
  },
  created() {
    this.findSites({ query: {} })
  },
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }

      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }

    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>

<template>
  <v-container fluid>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">{{formAction}} Site</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-select
                v-model="site.organisationId"
                :items="organisations"
                item-text="name"
                item-value="id"
                label="Select Organisation"
            ></v-select>
            <v-text-field label="Site Name" v-model="site.name" required></v-text-field>
            <v-text-field label="Description" v-model="site.description" required></v-text-field>
            <v-text-field label="Address 1" v-model="site.address1" required></v-text-field>
            <v-text-field label="Address 2" v-model="site.address2" required></v-text-field>
            <v-text-field label="Town" v-model="site.town" required></v-text-field>
            <v-text-field label="Postcode" v-model="site.postcode" required></v-text-field>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closeModal')">Cancel</v-btn>
        <v-btn @click="submit">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import feathersClient from "../../../../feathers-client";

export default {
  props: ['formAction', 'modelId'],
  metaInfo: {
    title: 'Site'
  },
  data() {
    return {
      valid: false,
      site: {
        name: '',
        description: '',
        address1: '',
        address2: '',
        town: '',
        postcode: '',
        organisationId: ''
      },
      organisations: []
    }
  },

  methods: {
    getOrganisations() {
      feathersClient.service('organisations').find({}).then((data) => {
        console.log(data);
      })

      this.$store.dispatch('organisations/find', {}).then((result) => {
            console.log(result);
            this.organisations = result.data;
          }
      );
    },
    getSite() {
      if(this.modelId !== null) {
        this.$store.dispatch('sites/get', this.modelId).then((result) => {
              this.site = result;
            }
        );
      }
    },
    submit() {
      this.$store.dispatch('sites/create', {
        name: this.name,
        description: this.description,
        address1: this.address1,
        address2: this.address2,
        town: this.town,
        postcode: this.postcode,
        organisationId: this.organisation_id
      })
    },
    mounted() {
      console.log("mounted");
      this.getOrganisations();
      this.getSite();
    },
  },

}
</script>
